import { combineReducers } from "redux";
import auth from "./auth/auth";
import partnerCompanies from "./companies/companies";
import momoTransactions from "./transactions/transactions";
import wifiData from "./wifi/wifi-data";
import roles from "./roles/Roles";
import permissions from "./permissions/Permissions";
import users from "./users/Users";

const allReducers = combineReducers({
  auth,
  partnerCompanies,
  momoTransactions,
  wifiData,
  roles,
  permissions,
  users,
});

export default allReducers;
