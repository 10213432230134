import React, { useState } from "react";
import "../styles/table.css";
import "../styles/search.scss";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AddIcon from "@mui/icons-material/Add";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Button } from "@mui/material";
import Datepicker from "react-tailwindcss-datepicker";
import { getTodayISO } from "../utils/getISOToday";
import { dateFormatter } from "../utils/currencyFormatter";
import TablePagination from "@mui/material/TablePagination";

const getTable = (data, tableType, HandleDelete, action) => {
  switch (tableType) {
    case "momo":
      return data?.map((row, index) => (
        <tr key={index}>
          <td>{index + 1}</td>
          <td>{row?.paymentResponseModel?.externalId}</td>
          <td>{row?.transactionId}</td>
          <td>{row?.phoneNumber}</td>
          <td>{row?.amount}</td>
          <td>{row?.paymentResponseModel?.status}</td>
          <td>{row?.companyName}</td>
          <td>{row?.date}</td>
        </tr>
      ));
    case "ussd":
      return data?.map((row, index) => (
        <tr key={index} onClick={() => action(row?.momoExternalTxRef)}>
          <td>{index + 1}</td>
          <td>{dateFormatter(row?.requestInitTime)}</td>
          <td>{row?.momoTxRef}</td>
          <td>{row?.cardNumber}</td>
          <td>{row?.phoneNumber}</td>
          <td>
            {row?.promotion ? row?.promotion : null}{" "}
            {row?.promotion ? "%" : "_"}
          </td>
          <td>{row?.promotionAmount}</td>
          <td>{row?.oldBalance}</td>
          <td>{row?.amount}</td>
          <td>{row?.newBalance || "_"}</td>
          <td>{row?.txStatus}</td>
        </tr>
      ));
    case "promotions":
      return data?.map((row, index) => (
        <tr key={index}>
          <td>{index + 1}</td>
          <td>{dateFormatter(row?.runningFrom)}</td>
          <td>{dateFormatter(row?.runningTo)}</td>
          <td>{row?.from}</td>
          <td>{row?.to}</td>
          <td>{row?.promValue} %</td>
          <td>{row?.active ? "ACTIVE" : "INACTIVE"}</td>
          <td style={{ textAlign: "center" }}>
            <Button onClick={() => action(row?.groupId)}>
              {row?.active ? (
                <ToggleOnIcon sx={{ color: "#00a2ff" }} />
              ) : (
                <ToggleOffIcon sx={{ color: "red" }} />
              )}
            </Button>
            {/* <Button>
              <DeleteForeverIcon sx={{ color: "red" }} />
            </Button> */}
          </td>
        </tr>
      ));
    case "wifi":
      return data?.map((row, index) => (
        <tr key={index}>
          <td>{index + 1}</td>
          <td>{row?.company}</td>
          <td>{row?.bus}</td>
          <td>{row?.onlinehours}</td>
          <td>{row?.offlinehours}</td>
          <td>{row?.simcard}</td>
        </tr>
      ));
    case "users":
      return data?.map((row, index) => (
        <tr key={index}>
          <td>{index + 1}</td>
          <td>{row?.firstName + " " + row?.lastName}</td>
          <td>{row?.phoneNumber}</td>
          <td>{row?.email}</td>
          <td>{row?.isActived}</td>
          <td>{row?.company?.name}</td>
          <td style={{ textAlign: "center" }}>
            <Button>
              <VisibilityIcon sx={{ color: "black" }} />
            </Button>
            <Button>
              <EditIcon sx={{ color: "#00a2ff" }} />
            </Button>
            <Button onClick={() => HandleDelete(row?.id)}>
              <DeleteForeverIcon sx={{ color: "red" }} />
            </Button>
          </td>
        </tr>
      ));
    case "companies":
      return data?.map((row, index) => (
        <tr key={index}>
          <td>{index + 1}</td>
          <td>{row?.name}</td>
          <td>{row?.compLead?.firstName + " " + row?.compLead?.lastName}</td>
          <td>{row?.compLead?.email}</td>
          <td>{row?.isActived ? "Active" : "Inactive"}</td>
          <td style={{ textAlign: "center" }}>
            <DeleteForeverIcon sx={{ color: "red" }} />
          </td>
        </tr>
      ));
    case "roles":
      return data?.map((row, index) => (
        <tr key={index}>
          <td>{index + 1}</td>
          <td>{row?.name}</td>
          <td>{row?.status}</td>
          <td>{row?.description ?? "-"}</td>
          <td style={{ textAlign: "center" }}>
            <DeleteForeverIcon sx={{ color: "red" }} />
          </td>
        </tr>
      ));
    case "permissions":
      return data?.map((row, index) => (
        <tr key={index}>
          <td>{index + 1}</td>
          <td>{row.name}</td>
          <td>{row.status}</td>
          <td>{row.description}</td>

          <td style={{ textAlign: "center" }}>
            <DeleteForeverIcon sx={{ color: "red" }} />
          </td>
        </tr>
      ));
    default:
      return <></>;
  }
};

const CustomTable = ({
  rows = [],
  columns = [],
  total = 0,
  tableType = "",
  tableTitle = "All records",
  HandleDelete,
  setDateValue,
  dateValue,
  action = null,
  useMultipleDates = false,
  viewMore = null,
  exportData = null,
  statistics,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState(rows);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleValueChange = (newValue) => {
    if (useMultipleDates) {
      setDateValue(newValue);
      console.log({ newValue });
    } else {
      setDateValue(newValue.startDate || "");
    }
  };
  const changeSearch = (e) => {
    setSearchQuery(e.target.value);
    setPage(0);
    setFilteredData(
      rows.filter((row) => {
        return Object.values(row)
          .join("")
          .toLowerCase()
          .includes(searchQuery.toLowerCase());
      })
    );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div className="table-wrapper">
      <div
        style={{
          padding: "13px",
          position: "sticky",
          background: "#fff",
          top: "60px",
        }}
      >
        {/* <hr /> */}
        <div className="table-container">
          <div className="table-header-wrapper">
            <div>
              <div className="mb-2">
                {tableType === "momo" || tableType === "ussd" ? (
                  <h3 className="font-bold">Total: {total}</h3>
                ) : (
                  <h3>{tableTitle}</h3>
                )}
              </div>
              <div>
                <Datepicker
                  asSingle={!useMultipleDates}
                  useRange={useMultipleDates}
                  value={dateValue}
                  placeholder={"Select data date"}
                  inputClassName="date-filter"
                  containerClassName="date-filter-input"
                  onChange={handleValueChange}
                  maxDate={new Date(getTodayISO())}
                  toggleClassName="toggle-date-filter"
                />
              </div>
            </div>
            <div className="flex max-w-[50%] overflow-y-scroll">
              {statistics?.length > 0 &&
                statistics.map((item, idx) => (
                  <div
                    key={idx}
                    className="bg-gray-200 rounded-sm mx-4 p-4 min-w-[14rem]"
                  >
                    <h3 className="font-bold">{item.title}</h3>
                    <p className="text-xs">
                      Successed: <span>{item.success}</span>
                    </p>
                    <p className="text-xs">
                      Failed: <span>{item.failed}</span>
                    </p>
                    <p className="text-xs">
                      Amount: <span>{item.amount}</span>
                    </p>
                  </div>
                ))}
            </div>
            <div className="left-side">
              {exportData && (
                <button onClick={() => exportData(filteredData)}>Export</button>
              )}
              {tableType === "ussd" && (
                <button onClick={action}>Promotions</button>
              )}
              {tableType === "promotions" && (
                <div
                  className="flex justify-center bg-[#00a2ff] rounded-md mr-6 cursor-pointer z-50"
                  onClick={action}
                >
                  <AddIcon fontSize="large" sx={{ mx: 2, color: "#fff" }} />
                </div>
              )}
              <div className="search-wrapper">
                <div className="search">
                  <input
                    type="text"
                    placeholder="Type to search..."
                    onChange={changeSearch}
                  />
                  <div>
                    <svg>
                      <use xlinkHref="#path" />
                    </svg>
                  </div>
                </div>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ display: "none" }}
                >
                  <symbol
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 160 28"
                    id="path"
                  >
                    <path
                      d="M32.9418651,-20.6880772 C37.9418651,-20.6880772 40.9418651,-16.6880772 40.9418651,-12.6880772 C40.9418651,-8.68807717 37.9418651,-4.68807717 32.9418651,-4.68807717 C27.9418651,-4.68807717 24.9418651,-8.68807717 24.9418651,-12.6880772 C24.9418651,-16.6880772 27.9418651,-20.6880772 32.9418651,-20.6880772 L32.9418651,-29.870624 C32.9418651,-30.3676803 33.3448089,-30.770624 33.8418651,-30.770624 C34.08056,-30.770624 34.3094785,-30.6758029 34.4782612,-30.5070201 L141.371843,76.386562"
                      transform="translate(83.156854, 22.171573) rotate(-225.000000) translate(-83.156854, -22.171573)"
                    ></path>
                  </symbol>
                </svg>
                <a
                  className="dribbble"
                  href="https://dribbble.com/shots/5547403-Search-input-animation"
                >
                  <img
                    src="https://dribbble.com/assets/logo-small-2x-9fe74d2ad7b25fba0f50168523c15fda4c35534f9ea0b1011179275383035439.png"
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="table-content-wrapper">
        <table>
          <tr className="actions-col">
            {columns.map((row, index) => (
              <th key={index + 1}>{row}</th>
            ))}
          </tr>
          {getTable(
            filteredData.slice(
              page * rowsPerPage,
              page * rowsPerPage + rowsPerPage
            ),
            tableType,
            HandleDelete,
            viewMore
          )}
        </table>
      </div>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={filteredData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default CustomTable;
