import { types } from "../../actions/types";

const initialState = {
  message: null,
  error: null,
  loading: false,
  transactions: null,
  ussdTransactions: null,
  promotions: null,
  promotionLoading: false,
  savePromotionSuccess: false,
  forceTransactionLoading: false,
  forceTransactionSuccess: false,
  activatePromotionLoading: false,
  activatePromotionSuccess: false,
  todayData: null,
  weekData: null,
  monthData: null,
};

export default function momoTransactions(state = initialState, action) {
  switch (action.type) {
    case types.TRANSACTIONS_PENDING:
      return {
        ...state,
        loading: true,
      };
    case types.GET_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message,
        transactions: action.transactions,
      };
    case types.TRANSACTIONS_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case types.USSD_TRANSACTIONS_PENDING:
      return {
        ...state,
        loading: true,
      };
    case types.GET_USSD_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message,
        ussdTransactions: action.data,
      };
    case types.USSD_TRANSACTIONS_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case types.USSD_PROMOTIONS_PENDING:
      return {
        ...state,
        loading: true,
      };
    case types.GET_USSD_PROMOTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message,
        promotions: action.data,
      };
    case types.USSD_PROMOTIONS_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case types.CREATE_PROMOTION_PENDING:
      return {
        ...state,
        promotionLoading: true,
        savePromotionSuccess: false,
      };
    case types.CREATE_PROMOTION_SUCCESS:
      return {
        ...state,
        promotionLoading: false,
        savePromotionSuccess: true,
      };
    case types.FORCE_TRANSACTION_PENDING:
      return {
        ...state,
        forceTransactionLoading: true,
        forceTransactionSuccess: false,
      };
    case types.FORCE_TRANSACTION_SUCCESS:
      return {
        ...state,
        forceTransactionLoading: false,
        forceTransactionSuccess: true,
      };
    case types.ACTIVATE_PROMOTION_PENDING:
      return {
        ...state,
        activatePromotionLoading: true,
        activatePromotionSuccess: false,
      };
    case types.ACTIVATE_PROMOTION_SUCCESS:
      return {
        ...state,
        activatePromotionLoading: false,
        activatePromotionSuccess: true,
      };
    case types.GET_TODAY_DATA:
      return {
        ...state,
        todayData: action.data,
      };
    case types.GET_WEEK_DATA:
      return {
        ...state,
        weekData: action.data,
      };
    case types.GET_MONTH_DATA:
      return {
        ...state,
        monthData: action.data,
      };
    default:
      return state;
  }
}
