export const types = {
  // User login and signup
  AUTH_PENDING: "AUTH_PENDING",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  AUTH_ERROR: "AUTH_ERROR",
  REGISTER_SUCCESS: "REGISTER_SUCCESS",
  REGISTER_ERROR: "REGISTER_ERROR",
  // Companies types
  COMPANIES_PENDING: "COMPANIES_PENDING",
  GET_COMPANIES_SUCCESS: "GET_COMPANIES_SUCCESS",
  REGISTER_COMPANY_SUCCESS: "REGISTER_COMPANY_SUCCESS",
  COMPANIES_ERROR: "COMPANIES_ERROR",
  // Transactions types
  TRANSACTIONS_PENDING: "TRANSACTIONS_PENDING",
  GET_TRANSACTIONS_SUCCESS: "GET_TRANSACTIONS_SUCCESS",
  TRANSACTIONS_ERROR: "TRANSACTIONS_ERROR",
  // Wifi data types
  WIFI_DATA_PENDING: "WIFI_DATA_PENDING",
  GET_WIFI_DATA_SUCCESS: "GET_WIFI_DATA_SUCCESS",
  WIFI_DATA_ERROR: "WIFI_DATA_ERROR",
  // Users types
  USERS_DATA_PENDING: "USERS_DATA_PENDING",
  GET_USERS_DATA_SUCCESS: "GET_USERS_DATA_SUCCESS",
  USERS_DATA_ERROR: "USERS_DATA_ERROR",
  // Roles types
  ROLES_DATA_PENDING: "ROLES_DATA_PENDING",
  GET_ROLES_DATA_SUCCESS: "GET_ROLES_DATA_SUCCESS",
  ROLES_DATA_ERROR: "ROLES_DATA_ERROR",
  // Permissions types
  PERMISSIONS_DATA_PENDING: "PERMISSIONS_DATA_PENDING",
  GET_PERMISSIONS_DATA_SUCCESS: "GET_PERMISSIONS_DATA_SUCCESS",
  PERMISSIONS_DATA_ERROR: "PERMISSIONS_DATA_ERROR",
  //auth users types
  AUTH_USER_DATA_PENDING: "AUTH_USER_PENDING",
  GET_AUTH_USER_DATA_SUCCESS: "GET_AUTH_USER_DATA_SUCCESS",
  AUTH_USER_DATA_ERROR: " AUTH_USER_DATA_ERROR",
  //updating user types
  USER_DATA_UPDATING: "USER_DATA_UPDATING",
  USER_DATA_UPDATING_SUCCESS: "USER_DATA_UPDATING_SUCCESS",
  USER_DATA_UPDATING_ERROR: "USER_DATA_UPDATING_ERROR",
  // ussd Transactions
  USSD_TRANSACTIONS_PENDING: "USSD_TRANSACTIONS_PENDING",
  GET_USSD_TRANSACTIONS_SUCCESS: "GET_USSD_TRANSACTIONS_SUCCESS",
  USSD_TRANSACTIONS_ERROR: "USSD_TRANSACTIONS_ERROR",
  // ussd Promotions
  USSD_PROMOTIONS_PENDING: "USSD_PROMOTIONS_PENDING",
  GET_USSD_PROMOTIONS_SUCCESS: "GET_USSD_PROMOTIONS_SUCCESS",
  USSD_PROMOTIONS_ERROR: "USSD_PROMOTIONS_ERROR",

  // create promotions
  CREATE_PROMOTION_PENDING: "CREATE_PROMOTION_PENDING",
  CREATE_PROMOTION_SUCCESS: "CREATE_PROMOTION_SUCCESS",
  CREATE_PROMOTION_ERROR: "CREATE_PROMOTION_ERROR",

  // force transactions
  FORCE_TRANSACTION_PENDING: "FORCE_TRANSACTION_PENDING",
  FORCE_TRANSACTION_SUCCESS: "FORCE_TRANSACTION_SUCCESS",
  FORCE_TRANSACTION_ERROR: "FORCE_TRANSACTION_ERROR",

  // Activate promotions
  ACTIVATE_PROMOTION_PENDING: "ACTIVATE_PROMOTION_PENDING",
  ACTIVATE_PROMOTION_SUCCESS: "ACTIVATE_PROMOTION_SUCCESS",
  ACTIVATE_PROMOTION_ERROR: "ACTIVATE_PROMOTION_ERROR",

  // ussd Statistics
  GET_TODAY_DATA: "GET_TODAY_DATA",
  GET_WEEK_DATA: "GET_WEEK_DATA",
  GET_MONTH_DATA: "GET_MONTH_DATA",
};
