import React from "react";
import { Modal, Backdrop, Fade } from "@mui/material";
import { Button } from "@mui/material";
import "../styles/NewForm.scss";
import {
  forceTransaction,
  getTransactions,
} from "../redux/actions/transactions/transactions";
import { useDispatch, useSelector } from "react-redux";

const TransactionDetails = ({ open, handleClose, details }) => {
  details["updatedAt"] = undefined;
  details["createdAt"] = undefined;

  const dispatch = useDispatch();
  const { forceTransactionLoading, forceTransactionSuccess } = useSelector(
    (state) => state.momoTransactions
  );

  const handleForceTransaction = async () => {
    const payload = { momoExternalTxRef: details["momoExternalTxRef"] };
    dispatch(forceTransaction(payload));
  };

  React.useEffect(() => {
    if (forceTransactionSuccess) {
      dispatch(getTransactions());
    }
  }, [forceTransactionLoading, forceTransactionSuccess]);

  const addSuffices = (key) => {
    const suffices = {
      amount: "Rwf",
      promotion: "%",
      oldBalance: "Rwf",
      newBalance: "Rwf",
      promotionAmount: "Rwf",
      totalAmount: "Rwf",
    };
    return suffices[key];
  };
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="modal"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className="paper">
          <h2>Transaction Details</h2>
          <div className="my-6">
            {Object.keys(details).map((item, idx) =>
              details[item] ? (
                <div className="flex my-2" key={idx}>
                  <p className="font-bold text-xs">
                    {item
                      .replace(/([a-z])([A-Z])/g, "$1 $2")
                      .replace(/tx/i, "Transaction")}
                    :
                  </p>
                  <p className="ml-4 text-xs">
                    {details[item]} {addSuffices(item)}
                  </p>
                </div>
              ) : null
            )}
          </div>
          <div className="flex justify-center my-4">
            {details?.newBalance <= details.oldBalance && (
              <Button
                size="small"
                className="create-button"
                variant="contained"
                onClick={handleForceTransaction}
              >
                {forceTransactionLoading ? "loading..." : "Force Transaction"}
              </Button>
            )}
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default TransactionDetails;
